<template>
  <div v-if="qrCodeString" class="d-flex border-top">
    <div class="block-qr">
      <QRcode
        :key="qrCodeString"
        :data="qrCodeString"
        size="115px"
        is-switch
      />
    </div>

    <div>
      <AppText variant="div" :line-height="1.5" style="max-width: 330px;">
        {{ t('signup.label.scanCode') }}
      </AppText>

      <SplitInput
        v-model="code"
        type="dashed"
        :state="error ? 'error' : ''"
        is-auto-clear-error
        hide-error-message
        class="code-input"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed, ref, watch,
} from 'vue';

import QRcode from '@/components/QRcode.vue';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { twoFaCodeGenerate } from '../api';

const props = defineProps({
  error: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: '',
  },
  isReady: {
    type: Boolean,
    default: true,
  },
});

const code = defineModel({ type: String, default: '' });

const qrCodeString = ref(null);

watch(computed(() => props.isReady && props.text), async () => {
  if (props.isReady) {
    const { otpLink } = await twoFaCodeGenerate(props.text);
    qrCodeString.value = otpLink;
  }
}, { immediate: true });
</script>

<style scoped lang="scss">
.border-top {
  border-top: 1px solid var(--color-black-01);
  border-radius: 4px;
  padding-top: 28px;
}
.block-qr {
  height: 112px;
  width: 112px;
  margin-right: 38px;
}
.code-input {
  margin-top: 18px;
}
</style>
