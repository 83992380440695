<template>
  <div class="delay">
    <FButton
      type="plain"
      :disabled="disabled || isClicked"
      is-full-width
      no-margin
      v-bind="$attrs"
      @click="onAction"
    >
      <slot />
      <span v-if="isClicked" style="width: 34px;">
        &nbsp;{{ formattedCounter }}
      </span>
    </FButton>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';

export default {
  name: 'DelayButton',
  inheritAttrs: false,
  props: {
    delay: {
      type: Number,
      default: 59,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    amountAllows: {
      type: Number,
      default: 99,
    },
  },
  emits: ['click', 'clear'],
  setup(props, { emit }) {
    const isClicked = ref(false);
    const timerId = ref(null);
    const intervalId = ref(null);

    const countDown = ref(props.delay);

    const formattedCounter = computed(() => {
      const minutes = Math.floor(countDown.value / 60);
      const seconds = countDown.value % 60;
      const formattedSeconds = seconds >= 10 ? seconds : `0${seconds}`;

      return `${minutes}:${formattedSeconds}`;
    });

    watch(computed(() => props.delay), (newValue) => {
      countDown.value = newValue;
    });

    const resetState = () => {
      isClicked.value = false;
      clickAmount.value = 0;
    };

    const delayButton = async () => {
      if (timerId.value) {
        clearTimeout(timerId.value);
      }
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }

      timerId.value = setTimeout(() => {
        resetState();
        if (isClicked.value) {
          clearTimeout(timerId);
        }
      }, props.delay * 1000);

      intervalId.value = setInterval(() => {
        countDown.value -= 1;

        if (Number(countDown.value) <= 0) {
          clearInterval(intervalId.value);
          resetState();
          countDown.value = props.delay;
          emit('clear');
        }
      }, 1000);
    };

    const clickAmount = ref(0);

    const setDelayImmediately = () => {
      isClicked.value = true;
      clickAmount.value += 1;
      delayButton();
    };

    if (props.immediate) {
      setDelayImmediately();
    }

    const onAction = async () => {
      if (props.disabled) return;

      if (!isClicked.value) {
        if (clickAmount.value >= props.amountAllows) {
          isClicked.value = true;
          await delayButton();
          clickAmount.value += 1;
          return;
        }
        emit('click');
        clickAmount.value += 1;
      }
    };

    watch(() => props.disabled, (value) => {
      if (value) {
        isClicked.value = false;
        clickAmount.value -= 1;
      }
    });

    return {
      isClicked,
      formattedCounter,
      onAction,
      setDelayImmediately,
    };
  },
};
</script>

<style lang="scss" scoped>
.is-clicked {
  &:hover {
    cursor: initial;
  }
}
</style>
