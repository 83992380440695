import { ref } from 'vue';

import { useTemporaryStore } from '@/store';
import { router } from '@/router';
import { fetchWalletData } from '@/api/merchantWallet';
import { getAssetsIncome, getTotalData } from '@/views/merchant/MerchantDashboard/api';

const makeLastElementBlured = () => {
  const inputElement = document.querySelector('.numbers-input .flex-item input');
  if (inputElement) {
    inputElement.focus();
    inputElement.blur();
  }
};

export const useLazyRequest = () => {
  const isLoading = ref(false);

  const onSuccessfullAuth = async () => {
    try {
      isLoading.value = true;
      makeLastElementBlured();

      const { replaceRefreshToken } = useTemporaryStore();
      replaceRefreshToken();

      // await fetchWalletData();
      // await getAssetsIncome();
      // await getTotalData();

      await Promise.all([
        fetchWalletData(),
        getTotalData(),
        getAssetsIncome(),
        // getMerchantProfile(),
        // getSettings(),
        // getWalletData(),
      ]);

      router.push({ name: 'DASHBOARD' });
      return { isSuccess: true };
    } catch (error) {
      isLoading.value = false;
      return { isSuccess: false };
    }
  };

  return { onSuccessfullAuth, isLoading };
};
