<template>
  <TitleBlock
    class="title-block"
    :title="t('signup.label.accountReady')"
    :subtitle="t('signup.label.accountReadyDescription')"
  >
    <TooltipCopy is-icon-hidden>
      <AppText
        class="uid font-medium"
        variant="div"
        size="34px"
        spacing="0.2em"
      >
        {{ userUid }}
      </AppText>
    </TooltipCopy>

    <FButton :loading="isLoading" style="margin-top: 50px;" @click="pushToHome">
      {{ t('signup.button.openDashboard') }}
    </FButton>
  </TitleBlock>
</template>

<script>
import { computed, defineComponent } from 'vue';

import TooltipCopy from '@/components/TooltipCopy.vue';

import { useTemporaryStore } from '@/store';
import { useLazyRequest } from '@/composables/useLazyRequest';

import TitleBlock from '../components/TitleBlock.vue';

export default defineComponent({
  components: {
    TitleBlock,
    TooltipCopy,
  },
  props: {
    uid: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { registration, replaceTemporaryStoreWithMain } = useTemporaryStore();

    const userUid = computed(() => props.uid || registration.value.uid);

    const { isLoading, onSuccessfullAuth } = useLazyRequest();

    const pushToHome = async () => {
      replaceTemporaryStoreWithMain({ uid: userUid.value });

      await onSuccessfullAuth();
    };

    return {
      userUid,
      pushToHome,

      isLoading,
    };
  },
});
</script>

<style scoped lang="scss">
.title-block {
  padding: 20px 0 30px;
}

.uid {
  width: 380px;
  height: 126px;
  background: url("~@/assets/images/bg_uid.svg") no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 38px;
  padding-right: 102px;
  margin-top: 12px;
}
</style>
