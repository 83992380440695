<template>
  <div class="flex-center flex-column">
    <AppText
      variant="div"
      size="22px"
      class="font-medium"
    >
      {{ title }}
    </AppText>

    <AppText
      variant="div"
      size="15px"
      :line-height="1.5"
      mt="24px"
      mb="42px"
      align="center"
      style="width: 340px;"
    >
      {{ subtitle }}
    </AppText>

    <div class="flex-center flex-column">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
});
</script>
