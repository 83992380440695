<template>
  <TitleBlock
    class="title-block"
    :title="t('signup.label.saveCodesTitle')"
    :subtitle="t('signup.label.saveCodesDescription')"
  >
    <AppTooltip>
      <template #text>
        {{ t('signup.label.saveCodes') }}
      </template>
      <div class="codes-wrapper" @click="onSaveCodes">
        <div class="codes">
          <template v-for="code in recoveryCodes" :key="code">
            <AppText size="17px" :line-height="2" variant="div" class="font-medium">
              {{ code }}
            </AppText>
          </template>
        </div>

        <AppIcon name="download" size="18px" :opacity="0.4" />
      </div>
    </AppTooltip>

    <DelayButton
      type="primary"
      immediate
      :is-full-width="false"
      :delay="5"
      class="mt-18"
      @click="onNext"
    >
      {{ t('signup.button.codesWereSaved') }}
    </DelayButton>
  </TitleBlock>
</template>

<script>
import { onBeforeMount, ref } from 'vue';

import DelayButton from '@/components/Buttons/DelayButton.vue';
import AppTooltip from '@/components/AppTooltip.vue';

import { router } from '@/router';
import { useTemporaryStore } from '@/store';
import { downloadAsFile } from '@/utils/functions';

import TitleBlock from '../components/TitleBlock.vue';
import { generateRecoveryCodes } from '../api';

export default {
  name: 'RecoveryCodes',
  components: {
    TitleBlock,
    DelayButton,
    AppTooltip,
  },
  emits: ['next'],
  setup(props, { emit }) {
    const recoveryCodes = ref([]);
    const isLoading = ref(true);

    onBeforeMount(async () => {
      isLoading.value = true;
      const { isSuccess, data, codesWasAdded } = await generateRecoveryCodes({
        isErrorNotification: false,
      });
      if (isSuccess) {
        recoveryCodes.value = data;
      }
      if (codesWasAdded) {
        router.push('/');
      }
      setTimeout(() => {
        isLoading.value = false;
      }, 200);
    });

    const onSaveCodes = () => {
      if (recoveryCodes.value.length) {
        downloadAsFile({
          data: recoveryCodes.value,
          filename: 'recovery-codes',
        });
      }
    };

    const { setNextStep } = useTemporaryStore();
    const onNext = () => {
      setNextStep();
      emit('next');
    };

    return {
      recoveryCodes,

      onSaveCodes,
      onNext,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.title-block {
  padding: 20px 0 30px;
}

.codes-wrapper{
  background: var(--color-F7F7F7);
  border-radius: 30px;
  border: 8px solid #E7E7E7;

  min-height: 146px;
  width: 368px;

  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;

  cursor: pointer;
  position: relative;

  &:hover {
    :deep(.icon) {
      opacity: 1;
    }
  }

  .codes {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
